@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
a {
  color: #009D97;
}
body {
  font-family: "Lato", sans-serif;
}
.thin-wrapper {
  display: flex;
  max-width: 990px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  height: 100vh;
  padding: 0 15px;
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-shadow: 0 1.4px 10px rgba(0, 0, 0, 0.045), 0 11px 80px rgba(0, 0, 0, 0.09);
  border-radius: 25px;
}

.logo {
  display: inline-block;
  text-align: center;
}

.logo img {
  max-width: 150px;
}

.title {
  text-align: center;
  font-size: 24px;
  color: #3C424A;
  margin-top: 1em;
}

.lost-paswd {
  font-size: 14px;
}

.form-login {
  max-width: 400px;
  margin: 0 auto;
}
.form-login table {
}

button {
}
button.full-width {
  width: 100%;
}
button.primary:hover {
  opacity: 0.8;
  background: #009D97;
  transition: all 200ms;
}

button.primary {
  background: #3C424A;
  color: #fff;
  margin-bottom: 1em;
}
button.outline {
  background: none;
  color: #e30815;
  border-color: #e30815;
}
button.outline:hover {
  background: #eee;
  border-color: #e30815;
}

.dash-card {
  background: #fff;
  box-shadow: 0 3.6px 7.9px rgba(0, 0, 0, 0.035),
    0 29px 63px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 4% 2% !important;
  color: #3ca935;
}
.dash-card span,
.dash-card svg {
  color: #e2e2e2;
  position: relative;
}
.dash-card span:before {
  content: "";
  width: 50px;
  height: 5px;
  position: absolute;
  background: #3ca935;
  border-radius: 100px;
  bottom: 0;
}

.dash-card:hover span:before {
  background: #fff;
}
.left-menu svg {
  color: #009D97;
}
.left-menu a {
  color: #333;
}
.left-menu a:hover {
  background: #009D97;
  color: #fff;
}
.left-menu a:hover svg {
  color: #fff;
}

.MuiPaper-elevation1 {
  box-shadow: 16px 11px 10px 0px rgb(223 214 214 / 20%),
    2px 2px 14px 8px rgb(236 236 236 / 14%), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.dash-card:hover {
  background: #3ca935;
  transition: all 200ms;
}
.dash-card:hover span {
  color: #fff !important;
}

.top-admin-bar {
  box-shadow: none !important;
  border-bottom: 1px solid #240a5117;
}
.top-admin-bar button svg path {
  color: #3C424A;
}
.top-admin-bar h6 {
  font-weight: 300;
  color: #b1b1b1;
  font-size: 1rem;
}

.MuiPaper-elevation1 {
  box-shadow: none;
}

.top-admin-bar {
  background: #fff;
}
.spacer {
  flex: 1;
}

.t-jumbotron {
  background: #f7f7f7;
}
.t-jumbotron.blue {
  background: #f8fbff;
}

.big-text {
  font-size: 2em;
}

.sumup {
  background: #3C424A;
  font-size: 1.2em;

  color: #fff !important;
}
.sumup input {
  color: #fff !important;
  font-size: 1.2em;
}

.auto-w {
  width: auto;
}

.login-panel {
  min-width: 400px;
}

.login-panel .MuiPaper-elevation1 {
  box-shadow: none !important;
  width: 100%;
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #000000;
}
.footer span {
  color: #ffffff;
  float: right;
  margin-right: 50px;
}
.footer a:hover {
  color: #3C424A;
}

.MuiCircularProgress-svg {
  color: #3C424A;
}

.HideErrors p{
  display: none;
}

/* .autocompleteinput input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-sizing: inherit;
}

.autocompleteinput div {
    margin-top: -8px;
    margin-bottom: -8px;
} */
